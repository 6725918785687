<template>
  <div class="about">
      <img class="topImg" src="@/assets/aboutUs/about.png" alt="">
      <div class="enterprise"> 
          <p class="enterpriseTitle">企业介绍</p>
          <img class="enterpriseImg" src="@/assets/aboutUs/bot_icon.png" alt="">
      </div>
      <p class="introduceContent">
        我们的初创团队在2018年上线了第一版的剁手记App，当时它就是一个单纯的返利App，有着和返利网，好省等App相似的优惠券返利和基础社交电商的功能，可惜还没有它们的流量。我们把这个App推荐给了身边的朋友使用，发现得到了大多数人的认同，而且只要学会了使用方法之后的朋友们一直在使用，有些已经通过它节省了好几千元，还通过虚拟网店赚了好几百元（他们有些人竟然全然不知自己只是分享了几次朋友圈就莫名其妙的有了几百元的收入）这让我们相信这是一个有一定的价值的App，我们可以把它做得更好让更多人使用。2020年，我们得到了华钦科技的天使轮投资，我们有了几十人的技术团队和市场团队，但是我们思考了“到底返利+社交电商该怎么做，能有什么不同，或者能给社会带来什么价值？”。经过思考我们认为将社交电商和助力实体以及助力流量主增值变现结合起来才是我们要做的事，我们现在以及将来做的一切都是围绕着这个使命。
      </p>
      <div class="activity">
        <img class="activityImg" src="@/assets/aboutUs/img_qiyejieshao.png" alt="">
      </div>  
      <div class="subItem">
          <img class="subItemImg" src="@/assets/aboutUs/yuanjing_img.png" alt="">
          <img class="subItemImg" src="@/assets/aboutUs/shiming_img.png" alt="">
          <img class="subItemImg" src="@/assets/aboutUs/value_img.png" alt="">
      </div>
      <div class="enterprise"> 
          <p class="enterpriseTitle">大事件</p>
          <img class="enterpriseImg" src="@/assets/aboutUs/bot_icon.png" alt="">
      </div>
      <div class="event">
          <div class="eventItem">
              <img class="eventItemImg" src="@/assets/aboutUs/2018year_img.png" alt="">
              <div class="eventMid">
                  <p class="eventcontent">第一版剁手记App上线</p>
              </div>
              <span class="eventItemBot">
                  first edition Duo shouji App Online
              </span>
          </div>
          <div class="eventItem">
              <img class="eventItemImg" src="@/assets/aboutUs/2019year_img.png" alt="">
              <div class="eventMid">
                  <p class="eventcontent">华钦科技（纳斯达克上市）入资始而信息科技</p>
              </div>
              <span class="eventItemBot">
                  Get angel round investment
              </span>
          </div>
          <div class="eventItem">
              <img class="eventItemImg" src="@/assets/aboutUs/2020year_img.png" alt="">
              <div class="eventMid">
                    <p class="eventcontent">剁手记2.0版上线</p>
                    <p class="eventcontent">企业福利店2.0版上</p>
                    <p class="eventcontent">全面开展支持实体和流量主业务</p>
              </div>
              <span class="eventItemBot">
                  Get angel round investment
              </span>
          </div>
          <div class="eventItem">
              <img class="eventItemImg" src="@/assets/aboutUs/2021year2_img.png" alt="">
              <div class="eventMid">
                  <p class="eventcontent">希望我们和广大用户一起开创！</p>
              </div>
              <span class="eventItemBot"> 
                  Create a better future together
              </span>
          </div>
      </div>
  </div>
</template>

<script>
export default {

}

</script>
<style scoped>
.about{
   width:100%;
}
.topImg{
    width:100%;
    height:3.2rem;
    margin-bottom:0.83rem
}
.enterprise{
    margin:0 auto;
    height:54px;
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
}
.enterpriseTitle{
    height: 40px;
    font-size: 28px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #333333;
    line-height: 40px;

}
.enterpriseImg{
    width:0.2rem;
    height:6px;
}
.introduceContent{
    width:67%;
    margin:0 auto;
    font-size:0.16rem;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    color: #333333;
    line-height: 42px;
    text-align: left;
}
.activity{
    width:67%;
    margin:0 auto;
    margin-top: 110px;
}
.activity>img{
    width: 100%;
}
.subItem{
    margin:110px auto 114px;
    width:67%;
    height:320px;
    display: flex;
   justify-content: space-between;
   align-items: center;
}
.subItem .subItemImg{
    width:4.06rem;  
    height: 100%;
}
.event{
    width:67%;
    display: flex;
    margin:80px auto 137px;

}
.eventItem{
    flex:1;
    height:2.13rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.eventItemImg{
    width:100%;
    /* height: 63px; */
}
.eventcontent{
    font-size:0.18rem;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    color: #333333;
    line-height: 0.26rem;

}
.eventItemBot{
    align-content:flex-end;
    justify-content: flex-end;
    font-size: 0.16rem;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    color: #666666;
    line-height:0.24rem;
    }
.eventMid{
    flex:1;
    margin-top:20px;
}
</style>